/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
}
.ais-Hits-item {
  width: 33%;
  box-shadow: none !important;
  padding: 0 !important;
  margin-top: 30px;
}
.ais-RefinementList-labelText {
text-transform: uppercase;
font-size: 16px;
font-weight: bold;
}

.card {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modal-content {
  border: 1px solid #fff;
  width: 500px;
  background-color: #fff;
  border-radius: 15px;
}
.exit-icon {
  cursor: pointer;
  font-size: 20px;
}
.modal-title {
  color: #6821c3;
  font-weight: 700;
  font-size: 30px;
  line-height: 28px;
}
.modal-image img {
  width: 8rem;
}
.modalText {
  text-align: justify;
}
.modal-button button {
  border: 1px solid #6821c3;
  padding: 6px;
  width: 8rem;
  background-color: #6821c3;
  color: white;
  border-radius: 7px;
}

.close-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 9999;
  font-size: 30px;
}

@media only screen and (max-width: 768px) {
  .ais-RefinementList-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .ais-Hits-item {
    width: 100%;
  }
}

.ais-SearchBox,
.ais-SearchBox-form  {
  height: 64px !important;
}

.ais-SearchBox .ais-SearchBox-input {
  height: 64px;
  background-color: #fff;
  border-radius: 8px;
  padding: 4px 48px 0 64px;
  font-family: Hind, sans-serif;
  box-shadow: 0 4px 48px rgba(0, 0, 0, .2);
}
.A2-level,
.a2-level {
  background-color: #62b283;
}
.B1-level,
.b1-level {
  background-color: #287550;
}
.B2-level,
.b2-level {
  background-color: #0f4f30;
}
.C1-level,
.c1-level {
  background-color: #8f62b2;
}


.placeholder-container {
  padding: 1%;
  background-color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.img-container {
  width: 100%;
  height: 144px;
}

.img {
  border: 1px solid white;
  height: 100%;
  background-color: #babbbc;
}

.content {
  border: 1px solid white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  justify-content: space-between;
  height: 200px;
}

.stripe {
  border: 1px solid white;
  height: 20%;
  background-color: #babbbc;
}

.small-stripe {
  width: 40%;
}

.medium-stripe {
  width: 70%;
}

.long-stripe {
  width: 100%;
}

.img-container .img,  .stripe {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

@keyframes hintloading
{
  0% {
    opacity: 0.5;
  }
  50%  {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes hintloading
{
  0% {
    opacity: 0.5;
  }
  50%  {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* CSS for flip animation and transcript styling */
  .card-container {
    perspective: 1000px;
    margin-bottom: 20px;
  }

  .card.flip-card {
    position: relative;
    width: 100%;
    min-height: 400px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .card.flip-card.is-flipped {
    transform: rotateY(180deg);
  }

  .card-face.flip-card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .card-face.flip-card-face.flip-card-back {
    transform: rotateY(180deg);
  }

  .transcript {
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .transcript::-webkit-scrollbar {
    display: none;
  }

  .speaker-name {
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    margin-bottom: 4px;
  }

  .highlight {
    background-color: yellow;
    transition: background-color 0.3s;
  }

  .flip-button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .flip-button svg {
    width: 24px;
    height: 24px;
  }

  .player-controls {
    margin-top: auto;
  }

  /* Ensure the Hits list is a flex container */
  .ais-Hits-list {
    display: flex;
    flex-wrap: wrap;
  }

  /* Each hit item takes up 33% width */
  .ais-Hits-item {
    width: 33%;
    box-shadow: none !important;
    padding: 0 !important;
    margin-top: 30px;
  }

  /* Responsive adjustments for smaller screens */
  @media only screen and (max-width: 768px) {
    .ais-Hits-item {
      width: 100%;
    }
  }

.button-play {
  box-shadow: 0 0 0 0 rgba(#fb7185, .5);
}

.button-play.pulse {
  animation: pulse 1.5s infinite;
}

.button-play:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(#fb7185, 0);
  }
    100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(#fb7185, 0);
  }
}

.copy-tooltip {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  z-index: 1000;
}

.pagination-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination-item {
  cursor: pointer;
}

.pagination-item:hover {
  background-color: #f5f5f5;
}

.pagination-item-selected > .ais-Pagination-link {
  background-color: #fb7185 !important;
  background-image: none;
  color: #fff;
}

.pagination-item-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.ais-Pagination-link {
  background-color: none !important;
  background-image: none;
  box-shadow: none;
  border-radius: 50%;
}